

































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { getSuppressionSources, getSuppressionDomain } from '@/api/consoleApi/suppressions'
import VsChart from '@/components/VsChart/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { orderBy } from 'lodash'

@Component({
    name: 'SuppressionStatistics',
    components: {
        VsChart,
        VsSectionHeader,
        VsLoader,
    },
})
export default class extends Vue {
    private loading = false
    private suppressionSources = []
    private suppressionDomain: any[] = []

    get barSuppressionsOptions () {
        return {
            tooltip: {
                trigger: 'axis',
                formatter: (payload: any) => {
                    return `<span class="vs-h200">${payload[0].seriesName}</span><br/>${payload[0].marker} ${payload[0].axisValue}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
                },
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                left: 15,
                right: 15,
                bottom: 0,
                top: 40,
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    margin: 15,
                    formatter: (value: string) => {
                        return this.$t(`lists.suppression.sources['${value}']`).toString()
                    },
                },
                nameTextStyle: {
                    color: '#60686A',
                },
                data: this.suppressionSources.map((el: any) => el.source),
            },
            yAxis: [
                {
                    type: 'value',
                    position: 'left',
                    nameTextStyle: {
                        color: '#60686A',
                    },
                    axisLabel: {
                        formatter: (value: number) => {
                            return this.$options?.filters?.formatNumber(value)
                        },
                    },
                },
            ],
            series: [
                {
                    name: 'Sorgente',
                    data: this.suppressionSources.map((el: any) => el.total),
                    type: 'bar',
                    showBackground: false,
                    stack: 'one',
                    itemStyle: {
                        color: '#DA5D6E',
                    },
                },
            ],
        }
    }

    get barDomainOptions () {
        return {
            tooltip: {
                trigger: 'axis',
                formatter: (payload: any) => {
                    return `<span class="vs-h200">${payload[0].seriesName}</span><br/>${payload[0].marker} ${payload[0].axisValue}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
                },
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                left: 15,
                right: 15,
                bottom: 0,
                top: 40,
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    rotate: 25,
                    margin: 15,
                },
                nameTextStyle: {
                    color: '#60686A',
                },
                data: this.suppressionDomain.map((el: any) => el.domain),
            },
            yAxis: [
                {
                    type: 'value',
                    position: 'left',
                    nameTextStyle: {
                        color: '#60686A',
                    },
                    axisLabel: {
                        formatter: (value: number) => {
                            return this.$options?.filters?.formatNumber(value)
                        },
                    },
                },
            ],
            series: [
                {
                    name: 'Dominio',
                    data: this.suppressionDomain.map((el: any) => el.total),
                    type: 'bar',
                    showBackground: false,
                    stack: 'one',
                    itemStyle: {
                        color: '#DA5D6E',
                    },
                },
            ],
        }
    }

    get user () {
        return UserModule.user
    }

    get statisticsDisabled () {
        return !this.user?.configuration?.rules.suppressionFull
    }

    async beforeMount () {
        if (this.statisticsDisabled) {
            this.$router.replace({
                name: 'suppressionList',
            })
        }
        this.loading = true
        await this.getSuppressionSources()
        await this.getSuppressionDomain()
        this.loading = false
    }

    private async getSuppressionSources () {
        try {
            const resp = await getSuppressionSources()
            this.suppressionSources = resp.data.data
        } catch (e) {
            this.suppressionSources = []
        }
    }

    private async getSuppressionDomain () {
        try {
            const resp = await getSuppressionDomain()
            this.suppressionDomain = orderBy(resp.data.data, 'total', 'desc').slice(0, 10)
        } catch (e) {
            this.suppressionDomain = []
        }
    }
}
