import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getSuppressions = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/suppressions`,
        {
            params,
            cancelToken,
        },
    )

export const createSuppression = (data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/suppressions`,
        data,
        {
            params,
            cancelToken,
        },
    )

export const deleteSuppression = (suppressionId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/suppressions/${suppressionId}`,
        {
            cancelToken,
        },
    )

export const getSuppressionSources = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/suppressions/sources`,
        {
            cancelToken,
        },
    )

export const getSuppressionDomain = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/suppressions/domains`,
        {
            cancelToken,
        },
    )

export const getSuppressionReport = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        '/suppressions/download',
        {
            cancelToken,
            responseType: 'blob',
        },
    )
